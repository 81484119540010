import { Address, Card } from "components";
import { FormattedMessage, useIntl } from "services";
import { Box, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { ChangeRequest, OrderAddress } from "models";
import { ChangeRequestInfoBox } from "components/common/ChangeRequestInfoBox";

const RequestChangeButton = styled.button({
  background: "none",
  border: "none",
  color: "#0069a0",
  textAlign: "left",
  textDecoration: "underline",
  cursor: "pointer",
});

interface Props {
  changeRequest: ChangeRequest | null;
  address: OrderAddress;
  toggleModal(modalIsOpen?: boolean | undefined): void;
  mode: "origin" | "destination";
}

export const AddressCounselingCard: React.FC<Props> = ({
  changeRequest,
  address,
  toggleModal,
  mode,
}) => {
  const { formatMessage } = useIntl();
  return (
    <Card
      title={<FormattedMessage id="counseling.addresses.title" />}
      aria-label={formatMessage({
        id:
          mode === "destination"
            ? "ariaLabels.addresses.destination.details"
            : "ariaLabels.addresses.origin.details",
      })}
      extraTitleContent={
        changeRequest && changeRequest.disallowResubmission ? null : (
          <Typography
            variant="sBody"
            component={RequestChangeButton}
            type={"button"}
            onClick={() => toggleModal(true)}
            aria-label={formatMessage({ id: "ariaLabels.requestChange" })}
          >
            <FormattedMessage id="actions.requestChange" />
          </Typography>
        )
      }
    >
      <Typography variant="xxsHeading">
        <FormattedMessage
          id={
            mode === "destination"
              ? "counseling.addresses.destination"
              : "counseling.addresses.origin"
          }
        />
      </Typography>
      <Address address={address} />
      {changeRequest ? (
        <Box mt="16px">
          <ChangeRequestInfoBox changeRequest={changeRequest} />
        </Box>
      ) : null}
    </Card>
  );
};
