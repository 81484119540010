import { MoveStageDate, MoveStageLabel } from "types";
import { FormattedMessage, useIntl } from "services";
import {
  formatDate,
  stringToZonedDateTimeFallbackLocal,
} from "@movehq/datetime";
import { Card } from "components";
import { DateChangeDialog } from "../DateChangeDialog";
import React, { useState } from "react";
import { Skeleton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import styled from "@emotion/styled";
import {
  Service,
  ServiceType,
  useGetDateCardDatesQuery,
} from "__generated__/types";
import { ChangeRequestInfoBox } from "components/common/ChangeRequestInfoBox";

const DatesTable = styled.table`
  width: 100%;
  table-layout: fixed;

  th,
  td {
    padding-top: 8px;
    vertical-align: top;
  }

  tbody {
    th {
      padding-top: 8px;
    }
  }
`;

export type DatesCardProps = {
  dates: Array<MoveStageDate>;
  orderId: string;
};

function DateCell({
  a11yDate,
  date,
  start,
  end,
  labelPrefix,
  testId,
  hasFootnote = false,
}: {
  a11yDate?: string | null;
  date?: string | null;
  start?: string | null;
  end?: string | null;
  labelPrefix: string;
  testId: string;
  hasFootnote?: boolean;
}) {
  const showDateRange = start && end && start !== end;
  return (
    <td>
      <Box data-testid={testId}>
        <Typography
          component="div"
          variant="sBody"
          aria-label={`${labelPrefix} ${a11yDate}`}
        >
          {date || "—"}
        </Typography>
        {showDateRange && (
          <Typography
            color="colors.grey30"
            variant="xxsBody"
            aria-label={`${labelPrefix} range`}
            component="div"
          >
            {start} - {end}
            {hasFootnote && `*`}
          </Typography>
        )}
      </Box>
    </td>
  );
}

function DateRow({ date }: { date: MoveStageDate }) {
  const preferredDateA11y =
    date.preferredDate && formatDate(date.preferredDate, "a11yDateFormat");
  const scheduledStartDateA11y =
    date.scheduledStartDate &&
    formatDate(date.scheduledStartDate, "a11yDateFormat");
  const completedDateA11y =
    date.completedDate && formatDate(date.completedDate, "a11yDateFormat");
  const preferredDate =
    date.preferredDate && formatDate(date.preferredDate, "twoDigitDayAndMonth");
  const scheduledStartDate =
    date.scheduledStartDate &&
    formatDate(date.scheduledStartDate, "twoDigitDayAndMonth");
  const scheduledEndDate =
    date.scheduledEndDate &&
    formatDate(date.scheduledEndDate, "twoDigitDayAndMonth");
  const completedDate =
    date.completedDate && formatDate(date.completedDate, "twoDigitDayAndMonth");
  const startDate =
    date.possibleStartDate &&
    formatDate(date.possibleStartDate, "twoDigitDayAndMonth");
  const endDate =
    date.possibleEndDate &&
    formatDate(date.possibleEndDate, "twoDigitDayAndMonth");
  const showFootnote =
    date.changeRequestKey === "delivery_date" && endDate !== null;
  return (
    <React.Fragment key={date.label}>
      <tr data-testid={`date-row-${date.label}`}>
        <th>
          <Typography variant="mHeading">{`${date.label}`}</Typography>
        </th>
        <DateCell
          a11yDate={preferredDateA11y}
          date={preferredDate}
          labelPrefix={"Requested Date"}
          start={startDate}
          end={endDate}
          testId={`DateCard-preferred-${date.label}`}
          hasFootnote={showFootnote}
        />
        <DateCell
          a11yDate={scheduledStartDateA11y}
          date={scheduledStartDate}
          start={scheduledStartDate}
          end={scheduledEndDate}
          labelPrefix={"Scheduled Date"}
          testId={`DateCard-scheduled-${date.label}`}
        />
        <DateCell
          a11yDate={completedDateA11y}
          date={completedDate}
          labelPrefix={"Completed Date"}
          testId={`DateCard-completed-${date.label}`}
        />
      </tr>
      {date.pendingChangeRequest ? (
        <tr>
          <td colSpan={4}>
            <ChangeRequestInfoBox
              changeRequest={date.pendingChangeRequest}
            ></ChangeRequestInfoBox>
          </td>
        </tr>
      ) : null}
      {showFootnote && (
        <tr>
          <td colSpan={4}>
            <Typography color="colors.grey30" variant="xxsBody" component="div">
              *Your Required Delivery Date (RDD) is {endDate}
            </Typography>
          </td>
        </tr>
      )}
    </React.Fragment>
  );
}

export const keyToLabelMap: Partial<Record<ServiceType, MoveStageLabel>> = {
  SERVICE_PACK: "Pack",
  SERVICE_LOAD: "Load",
  SERVICE_DELIVERY: "Delivery",
};

const addScheduledDatesFromServices = (
  services: Partial<Service>[],
  dates: MoveStageDate[]
): MoveStageDate[] => {
  return dates.map((date) => {
    const service = services.find(
      (svc) =>
        svc && svc.serviceType && keyToLabelMap[svc.serviceType] === date.label
    );

    const {
      plannedStartDatetime,
      plannedEndDatetime,
      plannedEndDatetimeZone,
      plannedStartDatetimeZone,
      actualEndDatetime,
      preferredStartDate,
      possibleStartDate,
      possibleEndDate,
    } = service || {};

    const scheduledStartDate =
      plannedStartDatetime && plannedStartDatetimeZone
        ? stringToZonedDateTimeFallbackLocal(plannedStartDatetime)
            .withTimeZone(plannedStartDatetimeZone)
            .toString()
        : undefined;

    const scheduledEndDate =
      plannedEndDatetime && plannedEndDatetimeZone
        ? stringToZonedDateTimeFallbackLocal(plannedEndDatetime)
            .withTimeZone(plannedEndDatetimeZone)
            .toString()
        : undefined;

    const completedDate =
      actualEndDatetime && plannedEndDatetimeZone
        ? stringToZonedDateTimeFallbackLocal(actualEndDatetime)
            .withTimeZone(plannedEndDatetimeZone)
            .toString()
        : undefined;

    return !service
      ? date
      : {
          ...date,
          // Fall back to the date on the order until we fully deprecate it
          scheduledStartDate,
          scheduledEndDate,
          preferredDate: preferredStartDate,
          possibleStartDate,
          possibleEndDate,
          completedDate,
        };
  });
};

export function DatesCard(props: DatesCardProps) {
  const { dates, orderId } = props;

  const { formatMessage } = useIntl();
  const [openModal, setOpenModal] = useState(false);

  const { data: dateCardData, loading: dateCardLoading } =
    useGetDateCardDatesQuery({
      variables: {
        orderId,
      },
    });

  const datesWithScheduled =
    dateCardData?.order?.services &&
    addScheduledDatesFromServices(dateCardData.order.services, dates);

  if (dateCardLoading || !datesWithScheduled) {
    return (
      <Skeleton
        height={250}
        variant={"rounded"}
        style={{ marginBottom: "1.5rem" }}
      />
    );
  }

  return (
    <>
      <Card
        title={<FormattedMessage id="dates.title" />}
        aria-label="Dates"
        data-test-id={"move-dates-card"}
        extraTitleContent={
          <Typography
            variant="sBody"
            component="a"
            onClick={() => setOpenModal(true)}
            aria-label={formatMessage({ id: "ariaLabels.requestDatesChange" })}
            data-testid="request-dates-change"
          >
            <FormattedMessage id="actions.requestChange" />
          </Typography>
        }
      >
        <DatesTable>
          <thead>
            <tr>
              <th></th>
              <th>
                <Typography variant="xxsHeading">
                  <FormattedMessage id="dates.preferredDate" />
                </Typography>
              </th>
              <th>
                <Typography variant="xxsHeading">
                  <FormattedMessage id="dates.scheduledDate" />
                </Typography>
              </th>
              <th>
                <Typography variant="xxsHeading">
                  <FormattedMessage id="dates.completedDate" />
                </Typography>
              </th>
            </tr>
          </thead>
          <tbody>
            {datesWithScheduled?.map((date) => (
              <DateRow date={date} key={date.label} />
            ))}
          </tbody>
        </DatesTable>
      </Card>
      {datesWithScheduled && (
        <DateChangeDialog
          openModal={openModal}
          setOpenModal={setOpenModal}
          moveStageDates={datesWithScheduled}
          orderId={orderId}
        />
      )}
    </>
  );
}
