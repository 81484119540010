"use client";
import { ApolloError } from "@apollo/client";
import {
  getShortTimeZone,
  jsDateToPlainDate,
  nowPlainDate,
  plainDateToJSDate,
  stringToPlainDate,
  Temporal,
} from "@movehq/datetime";
import HelpIcon from "@mui/icons-material/HelpOutlineOutlined";
import {
  Alert,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import {
  SurveyAvailabilitiesQuery,
  useCreateSurveyAppointmentMutation,
} from "__generated__/types";
import { BackNav, DatePicker, Footnote } from "components";
import { useCounselingSubmit } from "hooks";
import { useSurveyAvailabilities } from "hooks/useSurveyAvailabilities";
import { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "services";
import { theme } from "styles";
import { counselingStatusToPathMap } from "types";
import { CounselingButton } from "../components/CounselingButton";
import { CounselingLayout } from "../components/CounselingLayout";
import { TimeSlotList } from "./TimeSlotList";

type ErrorCode = "appointment_exists" | "not_available";

/**
 * Groups survey availabilities by date.
 *
 * @param data - The survey availabilities query result.
 * @returns A map where the keys are dates and the values are arrays of time slots.
 */
const groupAvailabilities = (data: SurveyAvailabilitiesQuery) => {
  type DateMap = {
    [s: string]: Array<{
      id: string;
      startTime: Temporal.PlainTime;
      endTime: Temporal.PlainTime;
    }>;
  };
  const dateMap = data.surveyAvailabilities.nodes.reduce((acc, curr) => {
    const startTime = Temporal.PlainTime.from(curr.startDatetime);
    const endTime = Temporal.PlainTime.from(curr.endDatetime);
    const day = stringToPlainDate(curr.startDatetime).toString();

    if (acc[day] === undefined) {
      acc[day] = [];
    }

    acc[day].push({
      startTime,
      endTime,
      id: curr.id,
    });
    return acc;
  }, {} as DateMap);
  return dateMap;
};

export function SurveySchedule() {
  const [selectedDate, setSelectedDate] = useState<Temporal.PlainDate | null>(
    null
  );
  const [selectedTimeSlotId, setSelectedTimeSlotId] = useState<string | null>(
    null
  );
  const [error, setError] = useState<ErrorCode | null>(null);
  const [isUnavailable, setIsUnavailable] = useState<boolean>(false);
  const [isInvalidDate, setIsInvalidDate] = useState(false);

  const {
    surveyAvailabilitiesData,
    surveyServiceData,
    surveyServiceLoading,
    refetch,
    timezone,
  } = useSurveyAvailabilities();

  const availabilitiesMap = surveyAvailabilitiesData
    ? groupAvailabilities(surveyAvailabilitiesData)
    : null;

  const timeSlots = useMemo(() => {
    if (availabilitiesMap && selectedDate) {
      return availabilitiesMap[selectedDate.toString()];
    }
  }, [availabilitiesMap, selectedDate]);
  const [datePickerIsOpen, setDatePickerIsOpen] = useState(false);
  const [datePickerWasOpened, setDatePickerWasOpened] = useState(false);

  const getErrorText = (error: ErrorCode) => {
    switch (error) {
      case "appointment_exists":
        return "This appointment has already been booked.";
      case "not_available":
        return "The selected survey slot is no longer available, please choose another time.";
    }
  };

  const now = nowPlainDate();
  const shortTimeZone = timezone
    ? getShortTimeZone(
        Temporal.ZonedDateTime.from({
          day: selectedDate?.day ?? now.day,
          month: selectedDate?.month ?? now.month,
          year: selectedDate?.year ?? now.year,
          hour: 12, // use midday to avoid issues with daylight saving transition days
          timeZone: timezone,
        })
      )
    : null;

  useEffect(() => {
    setSelectedTimeSlotId(null);
  }, [selectedDate]);

  useEffect(() => {
    if (
      !surveyServiceLoading &&
      surveyServiceData &&
      !surveyServiceData.pendingSurveyService
    ) {
      setError("appointment_exists");
    }
  }, [surveyServiceLoading, surveyServiceData]);

  const [createAppointment] = useCreateSurveyAppointmentMutation();

  const { handleNext, loading } = useCounselingSubmit({
    nextStatus: "COMPLETE",
    saveCallback: async () => {
      setError(null);
      if (!surveyServiceData?.pendingSurveyService) {
        await refetch();
        return true;
      }

      const { data, errors } = await createAppointment({
        variables: {
          input: {
            surveyServiceId: surveyServiceData?.pendingSurveyService?.id,
            surveyAvailabilityId: isUnavailable
              ? null
              : selectedTimeSlotId || "",
          },
        },
        onError: async (error) => {
          const code = error.graphQLErrors[0]?.extensions?.code as ErrorCode;
          setError(code);
          setSelectedTimeSlotId(null);
        },
      });

      const code = (errors as unknown as ApolloError)?.graphQLErrors[0]
        ?.extensions?.code as ErrorCode;

      if (code === "not_available") {
        await refetch();
        return false;
      }

      return code === "appointment_exists" || !!data;
    },
  });

  const isSingleAvailableDay = !!(
    surveyServiceData?.pendingSurveyService?.maxSurveyDate &&
    surveyServiceData?.pendingSurveyService?.minSurveyDate &&
    surveyServiceData?.pendingSurveyService?.maxSurveyDate ===
      surveyServiceData?.pendingSurveyService?.minSurveyDate
  );

  const OptOutOptionInfo = ({ type }: { type: "date" | "time" }) => {
    return (
      <Box
        sx={{
          mt: { xs: "24px", md: type === "date" ? "100px" : "292px" },
          mb: { xs: "24px" },
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={1}>
            <HelpIcon></HelpIcon>
          </Grid>
          <Grid item xs={11}>
            <Stack>
              <Typography variant="mHeading" component="h1" mb="12px">
                <FormattedMessage
                  id={
                    type === "date"
                      ? "counseling.surveySchedule.availability.date.heading"
                      : "counseling.surveySchedule.availability.time.heading"
                  }
                />
              </Typography>
              <Typography variant="sBody" component="p" mb="12px">
                <FormattedMessage
                  id={
                    type === "date"
                      ? "counseling.surveySchedule.availability.date.body"
                      : "counseling.surveySchedule.availability.time.body"
                  }
                />
              </Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isUnavailable}
                      onChange={(e) => {
                        setIsUnavailable(e.target.checked);
                        setSelectedTimeSlotId(null);
                      }}
                    />
                  }
                  label={
                    <Typography variant="sBody">
                      <FormattedMessage
                        id={
                          type === "date"
                            ? "counseling.surveySchedule.availability.date.label"
                            : "counseling.surveySchedule.availability.time.label"
                        }
                      />
                    </Typography>
                  }
                />
              </FormGroup>
            </Stack>
            <div></div>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const md = useMediaQuery(theme.breakpoints.up("md"));

  const getOptOutOptionInfo = () => {
    const displayDateOptOut =
      isSingleAvailableDay && selectedDate === null && datePickerWasOpened;
    const displayTimeOptOut = selectedDate !== null;

    if (displayDateOptOut) {
      return OptOutOptionInfo({ type: "date" });
    } else if (displayTimeOptOut) {
      return OptOutOptionInfo({ type: "time" });
    } else {
      return null;
    }
  };

  const handleSetSelectedTimeSlotId = (id: string) => {
    setSelectedTimeSlotId(id);
    setIsUnavailable(false);
  };

  return (
    <CounselingLayout
      ariaLabel={"ariaLabels.counseling.survey.schedule"}
      rightColumnContent={md ? getOptOutOptionInfo() : null}
    >
      <BackNav href={counselingStatusToPathMap.SURVEY_SCHEDULE_TYPE} />
      <CounselingLayout.Heading>
        <FormattedMessage id="counseling.surveySchedule.title" />
      </CounselingLayout.Heading>
      <CounselingLayout.InfoText>
        <FormattedMessage id="counseling.surveySchedule.instructions" />
      </CounselingLayout.InfoText>
      {!md && getOptOutOptionInfo()}
      {availabilitiesMap === null ? (
        <>Loading...</>
      ) : // There are no availabilities
      Object.keys(availabilitiesMap).length === 0 ? (
        <>
          <Alert severity="error">
            There are currently no available times to schedule your survey
            appointment. Please contact customer service.
          </Alert>
        </>
      ) : (
        <>
          <Box maxWidth={"500px"}>
            {surveyServiceLoading ? (
              <Skeleton variant="rectangular" width="100%" height="56px" />
            ) : null}
            {surveyServiceData?.pendingSurveyService ? (
              <DatePicker
                value={selectedDate && plainDateToJSDate(selectedDate)}
                onChange={(newDate: Date) => {
                  if (newDate !== null && !isNaN(newDate.getDate())) {
                    setSelectedDate(jsDateToPlainDate(newDate));
                    setIsInvalidDate(false);
                  } else {
                    setIsInvalidDate(true);
                  }
                }}
                minDate={plainDateToJSDate(
                  Temporal.PlainDate.from(
                    surveyServiceData.pendingSurveyService.minSurveyDate
                  )
                )}
                maxDate={plainDateToJSDate(
                  Temporal.PlainDate.from(
                    surveyServiceData.pendingSurveyService.maxSurveyDate
                  )
                )}
                label="Survey Request Date"
                disabled={isUnavailable}
                open={datePickerIsOpen}
                onOpen={() => {
                  setDatePickerIsOpen(true);
                  setDatePickerWasOpened(true);
                }}
                onClose={() => {
                  setDatePickerIsOpen(false);
                }}
              />
            ) : null}
            {selectedDate !== null && !isInvalidDate && timeSlots ? (
              <>
                <Box my="24px">
                  <p>
                    <FormattedMessage id="counseling.surveySchedule.selectTimeInstructions" />
                  </p>
                </Box>
                <TimeSlotList
                  timeSlots={timeSlots}
                  selected={selectedTimeSlotId}
                  onSelect={handleSetSelectedTimeSlotId}
                />
                <Footnote showMarker={false}>
                  {shortTimeZone && (
                    <FormattedMessage
                      id="counseling.surveySchedule.timeZone"
                      values={{
                        timezone: <strong>{shortTimeZone}</strong>,
                      }}
                    />
                  )}
                </Footnote>
              </>
            ) : null}
          </Box>
          {error ? (
            <Box my="24px">
              <Alert severity="error">{getErrorText(error)}</Alert>
            </Box>
          ) : null}
        </>
      )}
      <CounselingButton
        disabled={
          selectedTimeSlotId === null &&
          !isUnavailable &&
          !!surveyServiceData?.pendingSurveyService
        }
        onClick={handleNext}
        isSubmitting={loading}
      />
    </CounselingLayout>
  );
}
