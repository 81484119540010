import React, { useMemo } from "react";
import { FormattedMessage } from "services";
import { Card, Footnote } from "components/common/ui";
import { useOrder } from "store";
import { Typography } from "@mui/material";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import styled from "@emotion/styled";
import { translationKeys } from "services";
import { DepotStatus } from "types";
import { InfoBox } from "components/common";

type InfoBoxProps = {
  condition: boolean;
  message: translationKeys;
};

type WeightRowProps = {
  name: string;
  weight: number;
  footnoteMarker?: boolean;
  weightColor?: string;
};

const FootnoteMarker = styled.span({
  color: "#f94550",
});

const WeightTableRow = ({
  name,
  weight,
  weightColor,
  footnoteMarker,
}: WeightRowProps) => (
  <TableRow key={name}>
    <TableCell
      component="th"
      scope="row"
      sx={{ color: "inherit", borderBottom: "none", padding: "8px" }}
    >
      <Typography variant="mHeading">{name}</Typography>
      {footnoteMarker && <FootnoteMarker>*</FootnoteMarker>}
    </TableCell>
    <TableCell align="right" sx={{ borderBottom: "none", padding: "8px" }}>
      <Typography variant="lHeading" color={weightColor || "#0069a0"}>
        {weight !== null ? `${weight} lbs` : "--"}
      </Typography>
    </TableCell>
  </TableRow>
);

export function WeightsCard() {
  const {
    status,
    hasReweighPending,
    entitlementWeight,
    estimatedWeight,
    actualWeightSource,
    reweighNetWeight,
    proGearWeight,
    spouseProGearWeight,
    initialNetWeight,
    personalPropertyWeight,
  } = useOrder();
  const hasReweighSource = actualWeightSource === "REWEIGH";
  const reweighHigherThanLoad =
    reweighNetWeight !== null && actualWeightSource === "INITIAL";

  const getAboveEntitlementColor = () => {
    if (personalPropertyWeight > entitlementWeight) {
      return "#AD1919";
    }
    if (personalPropertyWeight <= entitlementWeight) {
      return "#115B42";
    }
    return "#105990";
  };

  const weightsData: WeightRowProps[] = useMemo(
    () => [
      {
        name: "Entitlement Weight",
        weight: entitlementWeight,
        footnoteMarker: true,
      },
      { name: "Estimated Weight", weight: estimatedWeight },
      { name: "Actual Weight", weight: initialNetWeight },
      { name: "Reweigh Weight", weight: reweighNetWeight },
      { name: "ProGear Weight", weight: proGearWeight },
      { name: "Spouse ProGear Weight", weight: spouseProGearWeight },
      {
        name: "Personal Property Weight",
        weight: personalPropertyWeight,
        weightColor: getAboveEntitlementColor(),
      },
    ],
    [
      entitlementWeight,
      estimatedWeight,
      initialNetWeight,
      reweighNetWeight,
      proGearWeight,
      spouseProGearWeight,
      personalPropertyWeight,
    ]
  );

  const infoBoxes: InfoBoxProps[] = useMemo(
    () => [
      { condition: hasReweighPending, message: "weights.reweigh.requested" },
      { condition: hasReweighSource, message: "weights.reweigh.isSource" },
      {
        condition: reweighHigherThanLoad,
        message: "weights.reweigh.higherThanLoad",
      },
    ],
    [hasReweighPending, hasReweighSource, reweighHigherThanLoad]
  );

  return (
    <Card title={<FormattedMessage id="weights.title" />}>
      <Table>
        <TableBody>
          {weightsData.map((row) => (
            <WeightTableRow key={row.name} {...row} />
          ))}
        </TableBody>
      </Table>
      {infoBoxes.map(
        (infoBox, index) =>
          infoBox.condition && (
            <InfoBox
              key={index}
              content={<FormattedMessage id={infoBox.message} />}
            />
          )
      )}
      <Footnote>
        <FormattedMessage id="weights.entitlement.footnote" />
      </Footnote>
    </Card>
  );
}
