import { Typography } from "@mui/material";
import { useDismissChangeRequestMutation } from "__generated__/types";
import { ChangeRequest } from "models";
import Link from "next/link";
import { useState } from "react";
import { useIntl } from "services/translation";
import { useOrder } from "store";
import { ChangeRequestStatus } from "types";
import { InfoBox } from "../InfoBox";

//---------------------
// TYPES
//---------------------
export type InfoBoxProps = {
  changeRequest: ChangeRequest<any>;
};

//---------------------
// UTILITY FUNCTIONS
//---------------------

const getColors = (status: ChangeRequestStatus) => {
  switch (status) {
    case "APPROVED":
      return "GREEN";
    case "SUBMITTED":
    case "REQUESTED":
      return "BLUE";
    case "REJECTED":
      return "RED";
  }
};

const showCloseButton = (status: ChangeRequestStatus) => {
  return status === "REJECTED" || status === "APPROVED";
};

//---------------------
// COMPONENT
//---------------------

export function ChangeRequestInfoBox({ changeRequest }: InfoBoxProps) {
  const { formatMessage } = useIntl();
  const order = useOrder();
  const [showInfoBox, setShowInfoBox] = useState<boolean>(true);
  const [dismissChangeRequest, { loading: dismissLoading }] =
    useDismissChangeRequestMutation();

  const infoBoxValidStatuses = [
    "SUBMITTED",
    "REQUESTED",
    "APPROVED",
    "REJECTED",
  ];

  const handleDismiss = async () => {
    if (changeRequest && changeRequest.id) {
      await dismissChangeRequest({
        variables: {
          input: {
            id: changeRequest.id,
          },
        },
      });

      setShowInfoBox(false);
      // Updates the local cache of the order object so that when re mounted the info box
      // doesn't suddenly appear again
      order.changeRequests = order.changeRequests.filter(
        (cr) => cr.id !== changeRequest.id
      );
    }
  };

  const title = () => {
    switch (changeRequest.name) {
      case "ORIGIN_ADDRESS":
      case "EXTRA_STOP_PICKUP":
      case "DESTINATION_ADDRESS":
      case "EXTRA_STOP_DELIVERY":
        return "Addresses";
      case "STORAGE_IN_TRANSIT":
        return "Storage";
      case "PACK_DATE":
      case "LOAD_DATE":
      case "DELIVERY_DATE":
        return "Dates";
      case "SIT_DELIVERY_ADDRESS":
        return "Storage delivery location";
    }
  };

  const label = () => {
    switch (changeRequest.name) {
      case "ORIGIN_ADDRESS":
        return formatMessage({
          id: "moveTracker.address.changeRequest.label.Origin",
        });
      case "EXTRA_STOP_PICKUP":
        return formatMessage({
          id: "moveTracker.address.changeRequest.label.ExtraPickup",
        });
      case "DESTINATION_ADDRESS":
        return formatMessage({
          id: "moveTracker.address.changeRequest.label.Destination",
        });
      case "EXTRA_STOP_DELIVERY":
        return formatMessage({
          id: "moveTracker.address.changeRequest.label.ExtraDelivery",
        });
      case "STORAGE_IN_TRANSIT":
        return formatMessage({
          id: "moveTracker.address.changeRequest.label.StorageRequested",
        });
      case "SIT_DELIVERY_ADDRESS":
        return formatMessage({
          id: "moveTracker.address.changeRequest.label.SitDeliveryAddress",
        });
      case "PACK_DATE":
        return "Pack Date";

      case "LOAD_DATE":
        return "Load Date";

      case "DELIVERY_DATE":
        return "Delivery Date";
    }
  };

  const endText = () => {
    switch (changeRequest.status) {
      case "SUBMITTED":
      case "REQUESTED":
        return formatMessage({
          id: "moveTracker.movingDate.changeRequest.textPart2",
        });
      case "APPROVED":
        return formatMessage({
          id: "moveTracker.movingDate.changeRequest.textPart3approved",
        });
      case "REJECTED":
        return formatMessage({
          id: "moveTracker.movingDate.changeRequest.textPart3",
        });
    }
  };

  if (!infoBoxValidStatuses.includes(changeRequest.status) || !showInfoBox) {
    return null;
  }

  return (
    <div data-testid={`change-request-info-${changeRequest.name}`}>
      <InfoBox
        title={title() + " - Change Request"}
        color={getColors(changeRequest.status)}
        onClose={
          showCloseButton(changeRequest.status) && !dismissLoading
            ? handleDismiss
            : undefined
        }
        content={
          <Typography variant="sBody">
            <span>Your request to update</span>
            <strong data-dd-privacy="mask">
              {" "}
              {label()} to {changeRequest.formatNewValue}
            </strong>
            <span> {endText()} </span>
            {changeRequest.disallowResubmission ? (
              <span>
                If you need to make a correction to your request please{" "}
                <Link href="/support">contact HSA Customer Care</Link>.
              </span>
            ) : null}
          </Typography>
        }
      />
    </div>
  );
}
