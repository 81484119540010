import { Skeleton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useGetServiceDocumentsQuery } from "__generated__/types";
import { Card } from "components";
import { FormattedMessage } from "services";

export function DocumentsCard({ orderId }: { orderId: string }) {
  const { data, loading } = useGetServiceDocumentsQuery({
    variables: {
      orderId,
    },
  });

  const documents = data?.serviceDocumentsByOrderId;
  return (
    <Card
      title={<FormattedMessage id="documents.title" />}
      aria-label="Order Documents List"
    >
      <Box component="ul" m="0" p="0" sx={{ listStyle: "inside none" }}>
        {loading && (
          <Skeleton
            variant="rounded"
            sx={{ bgcolor: "grey.200" }}
            height={56}
          />
        )}
        {!loading &&
          documents &&
          documents.map((document, index) => (
            <Box component="li" mb="5px" key={index} aria-label={document.name}>
              <div data-testid="pdf-document">
                <Typography variant="sBody">
                  <a
                    href={document.links.canonical ?? document.links.direct}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {document.service.displayName} - {document.displayName}
                  </a>
                </Typography>
              </div>
            </Box>
          ))}
      </Box>
    </Card>
  );
}
