import { Temporal } from "@movehq/datetime";
import { Box, Menu, MenuItem } from "@mui/material";
import { CalendarEvent, google, ics, outlook, yahoo } from "calendar-link";
import { Button } from "components";
import { Service } from "models";
import { useState } from "react";
import { FormattedMessage, translationKeys, useIntl } from "services";

interface Props {
  service: Service;
}

type CalenderOption = {
  id: string;
  titleId: translationKeys;
  imgUrl: string;
  link: (calendarEvent: CalendarEvent) => string;
};

export const AddToCalendar: React.FC<Props> = ({ service }) => {
  const { formatMessage } = useIntl();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  if (!service.plannedDatetimeRange || !service.plannedDatetimeRange.begin) {
    return null;
  }

  const plannedDateTime = Temporal.Instant.from(
    service.plannedDatetimeRange.begin
  ).toString();

  const event: CalendarEvent = {
    title: formatMessage({ id: "surveyPanel.addToCalendar.event.title" }),
    description: `Join a virtual survey with our survey agent to document all the items being moved for your upcoming move. \
        During this session, the agent will guide you through a video call to assess and record the inventory of your household goods. \
        Please <a href=\"${window.location.href}\">click this link</a> to join your Virtual Survey.`,
    start: plannedDateTime,
    duration: [30, "minutes"],
  };

  const onClickMenuItem = (cb: (calendarEvent: CalendarEvent) => string) => {
    handleCloseMenu();
    const url = cb(event);
    window.open(url, "_blank");
  };

  const calendarOptions: CalenderOption[] = [
    {
      id: "apple",
      titleId: "surveyPanel.addToCalendar.apple",
      imgUrl: "images/icons/calendar-options/appleLogo.png",
      link: ics,
    },
    {
      id: "google",
      titleId: "surveyPanel.addToCalendar.google",
      imgUrl: "images/icons/calendar-options/googleCalendar.png",
      link: google,
    },
    {
      id: "ical",
      titleId: "surveyPanel.addToCalendar.ical",
      imgUrl: "images/icons/calendar-options/iCalFileIcon.png",
      link: ics,
    },
    {
      id: "outlook",
      titleId: "surveyPanel.addToCalendar.outlook",
      imgUrl: "images/icons/calendar-options/outlookLogo.png",
      link: outlook,
    },
    {
      id: "yahoo",
      titleId: "surveyPanel.addToCalendar.yahoo",
      imgUrl: "images/icons/calendar-options/yahooLogo.png",
      link: yahoo,
    },
  ];

  return (
    <>
      <Button
        aria-controls={open ? "basic-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleOpenMenu}
        size="medium"
        variant="secondary"
      >
        <FormattedMessage id="surveyPanel.addToCalendar.button" />
      </Button>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        transformOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
        open={open}
        onClick={handleCloseMenu}
        onClose={handleCloseMenu}
        hideBackdrop
        MenuListProps={{
          sx: { width: anchorEl && anchorEl.offsetWidth },
        }}
      >
        {calendarOptions.map((op) => (
          <MenuItem
            key={op.id}
            onClick={() => {
              onClickMenuItem(op.link);
            }}
          >
            <Box mr={1} height={20}>
              <img src={op.imgUrl} height="100%" width="100%" />
            </Box>
            <FormattedMessage id={op.titleId} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
